@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-text-color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .react-grid-Row:hover {
  background: #e5faed !important;
} */

.react-grid-Cell {
  border: 0;
  background: none !important;
}

.rdg-selected {
  border: none !important;
}

.viewIcon {
  fill: gray !important;
  cursor: pointer;
}

.viewIconError {
  fill: #f44336 !important;
  cursor: pointer;
}
